// ---------------------------------------------- modules import
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FunctionComponent } from "react";
import { IDisclaimerPageProps, IResultPageDiscProps } from "./common";
import { AssessmentIdEnum } from "../../../../enums/assessment_id";

import BgCover from "../../../../assets/images/bg-disclaimer.png";
import UpdateModal from "../../../ui/modals/update_modal";

// ---------------------------------------------- styles
const styles = StyleSheet.create({
  page: {
    alignItems: "center",
    flexDirection: "column",
    padding: 40,
    position: "relative", // Tambahkan style position: relative
  },
  backgroundImage: {
    zIndex: 2,
    position: "absolute",
    width: "110%",
    height: "110%",
  },
  section: {
    marginBottom: 8,
    paddingTop: 140,
    paddingHorizontal: 40,
  },
  disclaimerText: {
    fontSize: 12,
    marginBottom: 6,
    color: "#000",
    zIndex: 3,
  },
  assessmentText: {
    fontSize: 12,
    marginBottom: 6,
    color: "#000",
    zIndex: 3,
    marginLeft: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 12,
    color: "#000",
    zIndex: 3,
  },
  bulletPointList: {
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 5,
  },
  bulletText: {
    fontSize: 12,
    marginRight: 5,
    textAlign: "justify",
  },
  bulletAssessmentText: {
    fontSize: 12,
    marginRight: 5,
    marginLeft: 10,
    textAlign: "justify",
  },
});

// ---------------------------------------------- the component
const DisclaimerPage: FunctionComponent<IDisclaimerPageProps> = ({
  worksheet,
}) => {
  const disclaimerItems = [
    "Ganesa.id (Gerbang Assessment Indonesia) adalah sebuah sistem pemetaan gaya belajar dan bakat minat untuk memberikan pandangan data tentang potensi diri",
    "Peruntukan penggunaan ganesa.id dalam lingkup internal pembinaan ganesa.id dalam pemetaan gaya belajar dan bakat minat",
    "Ganesa.id di buat atas pemetaan assessment diagnostik non kognitif",
    "Ganesa.id disusun oleh praktisi pendidikan dan konsultan psikolog",
    "Hasil dari ganesa.id dapat di gunakan 6 bulan sampai 1 tahun setelah tes dilaksanakan. di luar dari rentang waktu tersebut perlu melakukan tes kembali karena hasil dapat berubah dengan beberapa faktor (Lingkungan, teman, kondisi keluarga dan lain lain)",
    "Dengan menyetujui pernyataan di atas membebaskan ganesa.id dari tuntutan yang timbul karena penggunaan sistem diluar peruntukan yang di sepakati.",
    "Dalam laporan ini akan menampilkan mengenai:",
  ];

  const assessments = worksheet.questionnaires.map((questionnaire) => {
    let updatedName = questionnaire.assessment.id;
    if (updatedName === AssessmentIdEnum.KEBIASAAN_BELAJAR) {
      updatedName = AssessmentIdEnum.KEBIASAAN_BELAJAR;
    } else if (
      updatedName === AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
    ) {
      updatedName = AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL;
    } else if (updatedName === AssessmentIdEnum.ADVERSITY_QUOTIENT) {
      updatedName = AssessmentIdEnum.ADVERSITY_QUOTIENT;
    } else if (updatedName === AssessmentIdEnum.CFIT) {
      updatedName = AssessmentIdEnum.CFIT;
    } else if (updatedName === AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES) {
      updatedName = AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES;
    } else if (updatedName === AssessmentIdEnum.DISC) {
      updatedName = AssessmentIdEnum.DISC;
    } else if (updatedName === AssessmentIdEnum.GAYA_BELAJAR) {
      updatedName = AssessmentIdEnum.GAYA_BELAJAR;
    } else if (
      updatedName === AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3
    ) {
      updatedName = AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3;
    } else if (updatedName === AssessmentIdEnum.KECERDASAN_MAJEMUK) {
      updatedName = AssessmentIdEnum.KECERDASAN_MAJEMUK;
    } else if (updatedName === AssessmentIdEnum.KONDISI_KELUARGA) {
      updatedName = AssessmentIdEnum.KONDISI_KELUARGA;
    } else if (updatedName === AssessmentIdEnum.PERSONALITY) {
      updatedName = AssessmentIdEnum.PERSONALITY;
    } else if (updatedName === AssessmentIdEnum.RIASEC) {
      updatedName = AssessmentIdEnum.RIASEC;
    } else if (updatedName === AssessmentIdEnum.SPM) {
      updatedName = AssessmentIdEnum.SPM;
    }
    return {
      ...questionnaire.assessment,
      name:
        updatedName === AssessmentIdEnum.KEBIASAAN_BELAJAR
          ? "Pemetaan Kebiasaan Belajar Di Rumah"
          : updatedName ===
            AssessmentIdEnum.KESEJAHTERAAN_PSIKOLOGI_DAN_EMOSIONAL
          ? "Pemetaan Kesejahteraan Psikologi"
          : updatedName === AssessmentIdEnum.ADVERSITY_QUOTIENT
          ? "Pemetaan Kecenderungan Ketahanan Diri"
          : updatedName === AssessmentIdEnum.CFIT
          ? "Pemetaan Kecerdasan"
          : updatedName === AssessmentIdEnum.COLOURED_PROGRESSIVE_MATRICES
          ? "Pemetaan Kecerdasan"
          : updatedName === AssessmentIdEnum.DISC
          ? "Pemetaan Kecenderungan Karir"
          : updatedName === AssessmentIdEnum.GAYA_BELAJAR
          ? "Pemetaan Gaya Belajar"
          : updatedName === AssessmentIdEnum.GAYA_BELAJAR_SD_KELAS_1_SAMPAI_3
          ? "Pemetaan Gaya Belajar"
          : updatedName === AssessmentIdEnum.KECERDASAN_MAJEMUK
          ? "Pemetaan Kecenderungan Majemuk"
          : updatedName === AssessmentIdEnum.KONDISI_KELUARGA
          ? "Pemetaan Kondisi Keluarga"
          : updatedName === AssessmentIdEnum.PERSONALITY
          ? "Pemetaan Kecenderungan Kepribadian"
          : updatedName === AssessmentIdEnum.RIASEC
          ? "Pemetaan Kecenderungan Minat dan Bakat"
          : updatedName === AssessmentIdEnum.SPM
          ? "Pemetaan Kecenderungan Kecerdasan"
          : questionnaire.assessment.name,
    };
  });

  //----------------------------------------------- the content
  return (
    <Page size="A4" style={styles.page}>
      <Image source={BgCover} style={styles.backgroundImage} fixed />
      <View style={styles.section}>
        <Text style={styles.title}>DISCLAIMER</Text>
        {disclaimerItems.map((item, index) => (
          <View key={index} style={styles.bulletPointList}>
            <Text style={styles.bulletText}>•</Text>
            <Text style={styles.disclaimerText}>{item}</Text>
          </View>
        ))}
        {assessments.map((assessment, index) => (
          <View key={assessment.id} style={styles.bulletPointList}>
            <Text style={styles.bulletAssessmentText}>{index + 1}</Text>
            <Text style={styles.assessmentText}>{assessment.name}</Text>
          </View>
        ))}
      </View>
    </Page>
  );
};

export default DisclaimerPage;
